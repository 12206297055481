import styled from 'styled-components';
import React, { useState, useEffect } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faInstagram,
    faLinkedin,
    faTwitter,
    faVimeo,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
    faPodcast,
    faAngleDown,
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";
import {red} from "../styles";

const StyledHeader = styled.header`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
`;
const TopMenu = styled.ul`
  text-align: left;
  margin-left: 10px;
  margin-bottom: 0;
  > li {
    display: inline-block;
    margin-right: 5px;
    line-height: 51px;
  }
`;
const SkipNavigationWrapper = styled.div`
    text-align: left;
`;
const SkipNavigation = styled.a`
  padding: .75rem 2rem .75rem 2rem; 
  z-index: 1000;
  position: absolute;
  top: -3.5rem;
  background: #c35113;
  color: white;
  transition-property: top;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  :focus {
    top: -2px;
  }
`;
const Container = styled.div`
  max-width: 1170px;
`;
const Row = styled.div`
  align-items: center;
`;
const OrangeBreaker = styled.span`
  color: #fa6231;
  line-height: 24px;
`;
const ButtonRow = styled.div`
  max-width: 310px; 
  display: flex; 
  flex-direction: row;
  vertical-align: top; 
  margin-left: 15px; 
  margin-bottom: 20px;
`;
const ToXChangeIcon = styled.img`
  width: 20px;
`;
const HeaderTop = styled.div`
  border-top: 2px solid #0270d0;
  background-color: #003767;
  a {
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;
const SocialMediaIcons = styled.ul`
  margin: 10px 0;
  > li {
    display: inline-block;
    > a {
      width: 22px;
      height: 25px;
      margin-right: 7px;
      display: block;
    }
  }
`;
const SearchBoxWrapper = styled.section`
  background: transparent;
  background-image: none;
  border: 0;
  box-sizing: border-box;
  font-size: 16px;
  height: auto;
  left: 0;
  margin: 0;
  text-indent: unset;
  top: 0;
  transform: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  position: relative;
  padding: 8px 0;
`;
const SearchBox = styled.input`
  appearance: none;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  max-width: 420px;
  padding: 10px 10px 10px 40px;
  width: 100%;
  color: rgb(64, 64, 64);
  font-size: 14px;
  border: 1px solid rgb(2, 112, 208);
  border-radius: 4px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMwMDM3NjciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgY2xhc3M9IiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 10px 9px;
  background-size: 26px 26px;
  background-color: rgb(255, 255, 255);
`;
const HeaderMiddle = styled.div`
  background-color: #f6f4ed;
  padding: 0;
`;
const SecondOptions = styled.div`
  margin: 5px 0 0;
  text-align: right;
  font-size: 14px;
`;
const HeaderButtonWrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;
const FlexBar = styled.div`
  display: inline-block;
  vertical-align: top;
  flex: 0 1 auto;
  max-width: calc(100% - 280px);
  margin-bottom: 20px;
`;
const HeaderLoginInfo = styled.div`
  display: inline;
  border: 0;
  text-align: center;
  margin: 10px 0;
`;
const HoverBoxLogin = styled.div`
  display: flex;
  position: relative;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-end;
  height: 38px;
  padding-right: 7px;
  cursor: pointer;
`;
const LoginDropdown = styled.div`
  right: auto;
  display: block;
  position: absolute;
  z-index: 99;
  background-color: #fff;
  width: 211px;
  margin-left: 4px;
  text-align: left;
  padding: 0 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  background-repeat: no-repeat;
  left: -5px;
  top: 38px;
  > a {
    color: #707070;
    display: block;
    transition: .5s;
    position: relative;
    left: 0;
    :hover {
      left: 5px;
      font-weight: 500;
    }
    > li {
      border: none;
      margin-bottom: 0;
      border-bottom: 1px dashed #d3d3d3;
      padding: 8px 0;
      width: 100%;
      display: flex;
      .slide {
        padding-right: 5px;
        margin-left: 7px;
        :hover {
          font-weight: 700;
        }
      }
    }
  }
`;
const UserInfoButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: none;
`;
const WelcomeMessageWrapper = styled.span`
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 8px;
`;
const WelcomeMessageTop = styled.span`
  display: block;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  flex: 0 0 auto;
`;
const WelcomeMessageBottom = styled.span`
  display: block;
  text-align: left;
  line-height: 18px;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HoverBox = styled.div`
  display: inline-block;
  position: relative;
`;
const ToXChangeButton = styled.button`
  background-color: #003766;
  border-color: #003766;
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38;
`;
const SupportButton = styled.a`
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38;
  margin-left: 10px;
  transition: background .3s;
  background-color: ${red};
  border-color: ${red};
  :hover {
    border-color: #a9370e;
    background: #a9370e;
  }
`;

function Header({userData, linkPrefix}) {
    const [showLoginMenu, setShowLoginMenu] = useState(false);
    const [showToXChangeMenu, setShowToXChangeMenu] = useState(false);
    const navigate = useNavigate();

    const closeAllMenus = () => {
        setShowToXChangeMenu(false);
        setShowLoginMenu(false);
    }
    const onClickLoginMenu = () => {
        setTimeout(() => {
            setShowLoginMenu(!showLoginMenu);
        }, 0);
    };
    const onClickToXChangeMenu = () => {
        setTimeout(() => {
            setShowToXChangeMenu(!showToXChangeMenu);
        }, 0);
    };

    useEffect(() => {
        const onWindowClick = () => {
            closeAllMenus();
        }
        window.addEventListener("click", onWindowClick);
        return () => {
            window.removeEventListener("click", onWindowClick);
        };
    }, []);

    const logout = async e => {
        e.preventDefault();
        const result = await fetch(`/api/logout`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if ( result.status === 200 ) {
            navigate('/login');
            navigate(0);
        }
    }

    return (
        <StyledHeader id="header" className="no-print">
            <div className="row">
                <div className="col-12">
                    <noscript>
                        <p className="text-center mb-5 no-js">Javascript has been disabled in your browser settings. In
                            order to create an optimal experience for our website visitors, Javascript must be enabled.
                            Please adjust your browser settings to enable Javascript and press refresh before
                            proceeding. </p>
                    </noscript>
                </div>
            </div>
            <HeaderTop className="bg-theme-colored-orange border-top-theme-colored2-2px sm-text-center">
                <Container className="container">
                    <Row className="row">
                        <SkipNavigationWrapper className="col-xs-12">
                            <SkipNavigation href="#main" className="px-3 pt-2 pb-1 custom-skip">
                                Skip Navigation
                            </SkipNavigation>
                        </SkipNavigationWrapper>
                        <div className="col-8 col-xs-7 col-sm-6 col-md-4">
                            <div className="widget">
                                <TopMenu className="list-inline">
                                    <li><a className="text-white"
                                                                      href={`${linkPrefix}/help.asp`}>Help/FAQs</a></li>
                                    <li className="text-white">|</li>
                                    <li><a className="text-white" href={`${linkPrefix}/contact.asp`}>Contact
                                        Us</a></li>
                                </TopMenu>
                            </div>
                        </div>
                        <div className="col-4 col-xs-5 col-sm-6 col-md-4">
                            <div className="widget">
                                <SocialMediaIcons className="text-center">
                                    <li>
                                        <a href="https://www.facebook.com/societyoftoxicology"
                                           className="top-soc" target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faFacebookF} aria-label="facebook icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/sotoxicology" className="text-white top-soc"
                                           target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faInstagram} aria-label="instagram icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/society-of-toxicology-sot-/"
                                           className="text-white top-soc" target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faLinkedin} aria-label="linkedin icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.adversereactionspodcast.com" className="text-white top-soc"
                                           target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faPodcast} aria-label="Podcast icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/SOToxicology" className="text-white top-soc"
                                           target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faTwitter} aria-label="Twitter icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://vimeo.com/toxicology" className="text-white top-soc"
                                           target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faVimeo} aria-label="Vimeo icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCzyLrV6LQyO41m1E9NA8qCw"
                                           className="text-white top-soc" target="_blank" rel="noopener">
                                            <FontAwesomeIcon icon={faYoutube} aria-label="Youtube icon"
                                                             className="text-white"/>
                                        </a>
                                    </li>
                                </SocialMediaIcons>
                            </div>
                        </div>
                        {/*<div className="col-xs-12 col-sm-12 col-md-4">*/}
                        {/*    <SearchBoxWrapper role="search" data-ss360="true">*/}
                        {/*        <SearchBox type="search" id="searchMain" className="ss360SearchBox" placeholder="Enter your search"/>*/}
                        {/*    </SearchBoxWrapper>*/}
                        {/*</div>*/}
                    </Row>
                </Container>
            </HeaderTop>
            <HeaderMiddle>
                <Container className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-5 col-md-4 col-lg-3 hidden-xs pl-15"><a
                            className="menuzord-brand pull-left flip sm-pull-center" href={`${linkPrefix}/`}><img
                            src="/images/SOT_logo_Tag_OL.png" alt="SOT Society of Toxicology logo"/></a></div>
                        <div className="col-xs-12 col-sm-7 col-md-8 col-lg-9 mt-10 p-0">
                            <div className="col-md-12 header-text-container">
                                <SecondOptions className="header-text mb-0">
                                    <a href={`${linkPrefix}/groups/membership/joinSOT.asp`}>Join or Upgrade Your Membership</a>
                                    <OrangeBreaker>&nbsp;&nbsp; | &nbsp;&nbsp;</OrangeBreaker>
                                    <a href={`${linkPrefix}/dues.asp`}>Renew Your Dues</a>
                                    <OrangeBreaker>&nbsp;&nbsp; | &nbsp;&nbsp;</OrangeBreaker>
                                    <a href={`${linkPrefix}/volunteer`}>Volunteer</a>
                                </SecondOptions>
                            </div>

                            <HeaderButtonWrapper className="col-md-12">

                                {userData ? (
                                    <FlexBar>
                                        <HeaderLoginInfo id="login-or-photo2">
                                            <HoverBoxLogin>
                                                <UserInfoButton id="welcome-msg-wrapper" onClick={onClickLoginMenu}>
                                                    <WelcomeMessageWrapper>
                                                        <WelcomeMessageTop id="welcome-msg-top">Welcome,</WelcomeMessageTop>
                                                        <WelcomeMessageBottom id="welcome-msg-bottom">{userData.fullName}</WelcomeMessageBottom>
                                                    </WelcomeMessageWrapper>
                                                    <div className="login-arrow"><FontAwesomeIcon icon={faAngleDown}/></div>
                                                </UserInfoButton>
                                                <LoginDropdown style={!showLoginMenu ? {display: 'none'} : null}>
                                                    <a className="toxlink" href={`${linkPrefix}/memberProfile.asp`} data-feathr-click-track="true">
                                                        <li className="toxitem">
                                                            <div><FontAwesomeIcon icon={faAngleDoubleRight}/></div>
                                                            <div className="slide"><span>Member Profile</span></div>
                                                        </li>
                                                    </a>
                                                    <a className="toxlink" target="_blank"
                                                       href="https://www.aim-hq.com/netFORUMsot/eweb/DynamicPage.aspx?Webcode=MyEvents"
                                                       data-feathr-click-track="true">
                                                        <li className="toxitem">
                                                            <div><FontAwesomeIcon icon={faAngleDoubleRight}/></div>
                                                            <div className="slide"><span>My Events</span></div>
                                                        </li>
                                                    </a>
                                                    <a id="logoutSOT" className="toxlink" onClick={logout}
                                                       data-feathr-click-track="true">
                                                        <li className="toxitem">
                                                            <div><FontAwesomeIcon icon={faAngleDoubleRight}/></div>
                                                            <div className="slide"><span>Logout</span></div>
                                                        </li>
                                                    </a>
                                                </LoginDropdown>
                                            </HoverBoxLogin>
                                        </HeaderLoginInfo>
                                    </FlexBar>
                                ) : null}

                                <ButtonRow>
                                    <HoverBox>
                                        <div className="widget no-border sm-text-center mt-10 mb-10 m-0 block-button inline">
                                            <ToXChangeButton id="toxchange" onClick={onClickToXChangeMenu}
                                               className="btn btn-dark btn-theme-colored2b pl-xs-10 pr-xs-10 pl-sm-10 pr-sm-10 white mr-10">
                                                <ToXChangeIcon src="/images/X-toXchange-1.svg" alt="toxchange logo"/>
                                                &nbsp; ToXchange &nbsp; <FontAwesomeIcon icon={faAngleDown}/>
                                             </ToXChangeButton>
                                        </div>
                                        <LoginDropdown className="dropdown toxdrop" style={!showToXChangeMenu ? {display: 'none'} : null}>
                                            <a className="toxlink" href="http://www.toxchange.org/" target="_blank">
                                                <li className="toxitem">
                                                    <div><FontAwesomeIcon icon={faAngleDoubleRight}/></div>
                                                    <div className="slide"><span>ToXchange Landing Page</span></div>
                                                </li>
                                            </a>
                                            <a className="toxlink"
                                                    href="https://toxchange.toxicology.org/network/members"
                                                    target="_blank">
                                                <li className="toxitem">
                                                    <div><FontAwesomeIcon icon={faAngleDoubleRight}/></div>
                                                    <div className="slide"><span>Membership Directory</span></div>
                                                </li>
                                            </a>

                                            <a className="toxlink"
                                               href="https://toxchange.toxicology.org/network/members/profile"
                                               target="_blank">
                                                <li className="toxitem last">
                                                    <div><FontAwesomeIcon icon={faAngleDoubleRight}/></div>
                                                    <div className="slide"><span>My Profile</span></div>
                                                </li>
                                            </a>
                                        </LoginDropdown>
                                    </HoverBox>


                                    <div className="widget no-border sm-text-center mt-10 mb-10 m-0 block-button inline">
                                        <SupportButton href={`${linkPrefix}/support.asp`} className="btn btn-dark btn-theme-colored2 pl-xs-10 pr-xs-10 pl-sm-10 pr-sm-10">
                                            Support SOT
                                        </SupportButton>
                                    </div>
                                </ButtonRow>


                            </HeaderButtonWrapper>
                        </div>
                    </div>
                </Container>
            </HeaderMiddle>
        </StyledHeader>
    );
}

export default Header;
