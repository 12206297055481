import styled from 'styled-components';
import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";

const PageTitle = styled.h1`
  color: #FF5C20;
  font-size: 36px;
  font-weight: 600;
  line-height: 52px;
  margin: 0 0 10px;
`;
const Dot = styled.span`
  background-color: #FF5C20;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  position: relative;
  margin: 2px 8px;
`
const StyledNav = styled.nav`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  background-image: url("https://www.toxicology.org/images/_interior-banners/About-banner.jpg");
  background-size: cover;
  background-position-x: center;
  margin-bottom: 50px;
`;
const Container = styled.div`
  padding: 50px 15px;
  max-width: 1170px;
  text-align: left;
`;
const Items = styled.div`
  display: inline-block;
  list-style-type: decimal;
  padding: 0px;
  color: white;
`
const Item = styled.span`
  color: white;    
  padding: 8px 0;
  margin-bottom: 20px;
  list-style: none;
  display: inline-block;
  font-size: 14px;
`
const ActiveItem = styled.span`
  color: white;    
  padding: 8px 0;
  margin-bottom: 20px;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
`

function Nav({linkPrefix}) {
    const [showLoginMenu, setShowLoginMenu] = useState(false);
    const [showToXChangeMenu, setShowToXChangeMenu] = useState(false);
    const {cycleName} = useParams();
    const [cycle, setCycle] = useState(null);

    const closeAllMenus = () => {
        setShowToXChangeMenu(false);
        setShowLoginMenu(false);
    }
    const onClickLoginMenu = () => {
        setTimeout(() => {
            setShowLoginMenu(!showLoginMenu);
        }, 0);
    };
    const onClickToXChangeMenu = () => {
        setTimeout(() => {
            setShowToXChangeMenu(!showToXChangeMenu);
        }, 0);
    };

    useEffect(() => {
        const onWindowClick = () => {
            closeAllMenus();
        }
        window.addEventListener("click", onWindowClick);
        return () => {
            window.removeEventListener("click", onWindowClick);
        };
    }, []);

    useEffect(() => {
        const fetchCycles = async () => {
            const result = await fetch('/api/getCycles', {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                
                
                const { cycles } = await result.json();

                if(!cycles || cycles.length == 0) return () => {};

                let cycleFound;
                if(cycleName) {
                    cycleFound = cycles.find(x => x.name == cycleName);
                }

                // If we can't find the cycle by it's name (or a name was not provided), we will try to default them to the latest possible cycle.
                if(!cycleFound) {
                    cycleFound = cycles[cycles.length - 1];
                }

                setCycle(cycleFound);
                
        };
        fetchCycles().catch(console.error);
    }, []);

    const title = cycle ? `Official Ballot ${cycle.name}` : '';

    return (
        <StyledNav>
            <div className="main-content">
                <Container className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-6">
                                <PageTitle>{title}</PageTitle>
                                <Items>
                                    <Item>Home</Item>
                                    <Dot/>
                                    <ActiveItem>{title}</ActiveItem>
                                </Items>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </StyledNav>
    );
}

export default Nav;
