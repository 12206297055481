import styled from 'styled-components';
import React, {useState} from 'react';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import { red } from "../styles";

const Page = styled.div`
    text-align: left;
`;
const LoginButton = styled.button`
  color: white;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid ${red};
  border-radius: 0.375rem;
  background-color: ${red};
  opacity: 0.9;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #212529;
    border-color: #212529;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    opacity: 0.8;
  }
`;

function Login({}) {
    const {
        search,
    } = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const submit = async () => {
        try {
            setError('');
            let result = await fetch(`/api/login`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password,
                })
            });
            let { errorMessage } = await result.json();
            if ( errorMessage || result.status === 401) {
                setError('Invalid email or password');
            } else {
                let query = new URLSearchParams(search)
                let redirect = query.get('redirect');
                let url = redirect && redirect.startsWith('/') ? redirect : '/ballot';
                navigate(url, {replace: true});
                navigate(0);
            }
        } catch (e) {
            setError('An error occurred when attempting to login');
        }
    };
    return (
        <Page>
            <div className="row">
                <div className="col-xs-12">
                    <p id="login-intro">Login is only available for members of the Society of Toxicology or those
                        participating in SOT events or programs.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4 mb-40">
                    <div>
                        <div className="form-group">
                            <label htmlFor="SOTlogin">Email:</label>
                            <input id="SOTlogin" name="SOTlogin" className="form-control" type="text"
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   autoComplete="username"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="SOTpw">Password:</label>
                            <input id="SOTpw" name="SOTpw" className="form-control" type="password"
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   autoComplete="current-password"/>
                        </div>

                        <br/>
                        <div className="form-group  mt-10">
                            <LoginButton id="login-submit" type="submit" onClick={submit}
                                    disabled={!password || !email}
                                    className="mr-10 mb-xs-20">Login
                            </LoginButton>
                        </div>
                        {
                            error ? (
                                <React.Fragment>
                                    <br/>
                                    <div className="alert alert-warning" role="alert">
                                        {error}
                                    </div>
                                </React.Fragment>
                            ) : null
                        }
                        <div className="clear text-left">
                            <p><a id="reset-password" className="text-theme-colored"
                                  href="https://www.aim-hq.com/netFORUMsot/eweb/DynamicPage.aspx?webcode=ForgotPassword&amp;Site=SOT"
                                  data-feathr-click-track="true"
                                  data-feathr-link-aids="[&quot;5f3d5ab794d4456c741321a7&quot;]">Reset
                                Password</a></p>
                        </div>
                        <br/>
                    </div>

                </div>
            </div>
        </Page>
    );
}

export default Login;
