import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleUp,
} from '@fortawesome/free-solid-svg-icons'

const StyledScrollToTop = styled.a`
  background: rgba(0, 0, 0, 0.1);
  bottom: 15px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 6px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  z-index: 99999;
  &, > * {
    color: #fff;
    font-size: 42px;
  }
`;

const threshold = 500;
function throttle (callbackFn, limit) {
    let wait = false;
    return function () {
        if (!wait) {
            callbackFn.call();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, limit);
        }
    }
}

function ScrollToTop() {
    const [scrolledDown, setScrolledDown] = useState(0);
    const onClick = e => {
        e.preventDefault();
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const updatePosition = throttle(() => {
            setScrolledDown(window.pageYOffset > threshold);
        }, 100);
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);



    return scrolledDown ? (
        <StyledScrollToTop href="#" onClick={onClick}><FontAwesomeIcon icon={faAngleUp}/></StyledScrollToTop>
    ) : null;
}

export default ScrollToTop;
