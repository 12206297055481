import styled from "styled-components";

export const orange = '#d6410a';
export const blue = '#004683';
export const gray = '#6D6E71';
export const red = '#d6410a';

export const PrinterBreak = styled.div`
  @media print {
    /*page-break-after: always;*/
  }
`
