import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {red} from "../styles";

const Page = styled.main`
  text-align: left;
  color: #3c3b3b;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red}
`;
const Office = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  line-height: 1.7;
`;
const Candidate = styled.div`
`;

function Admin({}) {
    const {cycleName} = useParams();
    const [cycle, setCycle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ballot, setBallot] = useState(null);
    const navigate = useNavigate();
    const {
        pathname
    } = useLocation();

    useEffect(() => {
        const fetchCycles = async () => {
            setIsLoading(true);
            const result = await fetch(`/api/getCycles`, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if ( result.status === 401 ) {
                navigate('/login?redirect=' + encodeURIComponent(pathname));
            } else {
                setIsLoading(false);
                const { cycles } = await result.json();

                if(!cycles || cycles.length == 0) return () => {};

                let cycleFound;
                if(cycleName) {
                    cycleFound = cycles.find(x => x.name == cycleName);
                }

                // If we can't find the cycle by it's name (or a name was not provided), we will try to default them to the latest possible cycle.
                if(!cycleFound) {
                    cycleFound = cycles[cycles.length - 1];
                }

                setCycle(cycleFound);
            }
            setIsLoading(false);
        };

        fetchCycles().catch(console.error);
    }, []);

    const exportCsv = async e => {
        e.preventDefault();

        setIsLoading(true);
        await fetch(`/api/listVotes?cycleId=${cycle.id}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        setIsLoading(false);
    };

    useEffect(() => {
        if(!cycle) return ()=> {};

        const fetchVoteSummary = async () => {
            setIsLoading(true);
            const result = await fetch(`/api/voteSummary?cycleId=${cycle._id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if ( result.status === 401 ) {
                navigate('/login?redirect=' + encodeURIComponent(pathname));
            } else if ( result.status === 403 ) {
                setError("You do not have access to this page");
            } else if ( result.status === 200 ) {
                const { ballot } = await result.json();
                setError(null);
                setBallot( ballot );
            } else {
                setError("There are no votes at the moment.");
            }
            setIsLoading(false);
        };
        
        fetchVoteSummary().catch(console.error);
    }, [cycle]);

    if ( isLoading ) {
        return (
            <Page>
                <Loading>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                </Loading>
            </Page>
        );
    } else if ( error ) {
        return (
            <span className="row">
                <span className="alert alert-danger" role="alert">
                    {error}
                </span>
                <br/>
            </span>
        );
    }

    return (
        cycle && ballot &&
        <Page>
            <Link to={'/ballot/preview/' + cycleName}>Preview: {ballot.title}</Link>
            <br/>
            <br/>
            <a href={'/api/listVotes?cycleId=' + cycle._id} target="_blank">{ballot.title} Voter Export</a>
            <br/>
            <br/>
            {ballot.offices.map(office => (
                <div key={office.id}>
                    <Office>{office.name}</Office>
                    {office.candidates.map(candidate => (
                        <React.Fragment key={candidate.id}>
                            <Candidate>{candidate.name}, {candidate.titles} ({candidate.votes || 0})</Candidate>
                        </React.Fragment>
                    ))}
                    <Candidate>Write In ({office.writeIns ? office.writeIns.length : 0})</Candidate>
                    <br/>
                    {
                        office.writeIns && office.writeIns.length ? (
                            <React.Fragment>
                                <Candidate>Write Ins:</Candidate>
                                {office.writeIns.map(writeIn => (
                                  <React.Fragment key={writeIn}>
                                    <Candidate>{writeIn}</Candidate>
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                        ) : null
                    }
                    <br/>
                </div>
            ))}
            <br/>
            <br/>
        </Page>
    );
}

export default Admin;
