import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Ballot from './components/ballot/Ballot';
import Login from './components/Login';
import Admin from './components/Admin';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "ballot/:cycleName",
                element: <Ballot preview={false} />,
            },
            {
                path: "ballot/preview/:cycleName",
                element: <Ballot preview={true} />,
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "admin/:cycleName",
                element: <Admin />,
            },
            {
                path: "*",
                element: <Navigate to="/ballot/2024-2025" replace/>,
            }
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
